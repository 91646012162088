import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACT_MENU_SELECT_ITEM } from "../store/actions";
import { selectConnectedUser } from "../store/selectors";
import { Typography, Alert } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

function UserPage() {
    const dispatch = useDispatch()
    const connectedUser = useSelector(selectConnectedUser());

    useEffect(() => {
        document.title = "Cinapps - Profil"
        dispatch(ACT_MENU_SELECT_ITEM())
    }, [dispatch])
    
    return (
        <>
            <Alert sx={{ mb:2 }} severity="warning">Cette page est en construction !</Alert>
            <div style={{ display:"flex" }}>
                <AccountCircleIcon fontSize="large" sx={{ mr: 2 }} />
                <div>
                    <Typography sx={{ mb:2 }} variant="h4">{connectedUser.firstName} {connectedUser.lastName}</Typography>
                    <Typography variant="body1"><b>Nom d'utilisateur:</b> {connectedUser.username}</Typography>
                    <Typography variant="body1"><b>Prénom:</b> {connectedUser.firstName}</Typography>
                    <Typography variant="body1"><b>Nom:</b> {connectedUser.lastName}</Typography>
                </div>
            </div>
        </>
    )
}

export default UserPage