import { Alert, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectMessageAlert } from "../../store/selectors"
import { ACT_MESSAGE_ALERT } from "../../store/actions"
import MessageAlert from "../../models/MessageAlert"
import { useEffect } from "react"
import SlideTransition from "./SlideTransition"

const SnackMessage = () => {
    const messageAlert = useSelector(selectMessageAlert())
    const dispatch = useDispatch()

    const handleMessageClose = () => {
        const newMessage: MessageAlert = { open: false, message: "", severity: undefined }
        dispatch(ACT_MESSAGE_ALERT(newMessage as any))
    }

    return (
        <>
            { messageAlert ? (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={messageAlert.open}
                onClose={() => handleMessageClose()}
                autoHideDuration={4000}
                TransitionComponent={SlideTransition}>
                <Alert onClose={() => handleMessageClose()} severity={messageAlert.severity} sx={{ width: '100%' }}>
                    {messageAlert.message}
                </Alert>
            </Snackbar>
            ) : (null)}
        </>
    )
}

export default SnackMessage