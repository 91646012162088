import { configureStore } from "@reduxjs/toolkit"
import { menuReducer, messageReducer, userReducer } from './reducers'

const store = configureStore({
    reducer: {
        menu: menuReducer,
        user: userReducer,
        message: messageReducer
    }
})

export default store