import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { Button, Divider, Paper, TextField, Typography } from "@mui/material"
import AppTitle from "../../components/typographies/AppTitle/AppTitle"
import "./LoginPage.css"
import { theme } from "../../styles/theme"
import api from "../../services/api"
import { ACT_USER_CONNECT } from "../../store/actions"
import { selectConnectedUser } from "../../store/selectors"

const LoginPage = () => {
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [usernameError, setUsernameError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [userLocalProfile, setUserLocalProfile] = useState<string | null>(localStorage.getItem("user"))
    const [username, setUsername] = useState<string>("");
    const [userPassword, setUserPassword] = useState<string>("");
    const connectedUser = useSelector(selectConnectedUser())
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = "Cinapps - Connexion"

        if(userLocalProfile){
            const userData = JSON.parse(userLocalProfile)
            if(userData.token){
                checkToken(userData.token)
            } else {
                setDataLoading(false)
            }
        } else {
            setDataLoading(false)
        }
    }, [])

    const connectUser = async() => {
        try {
            setDataLoading(true)
            const response = await api.post("/login/check", {
                username: username,
                password: userPassword
            })
            .catch((error) => {
                return error.response
            })
            
            if(response.status === 200){
                const resultat = response.data;
                if(resultat.token) {
                    const localUser = {
                        token: resultat.token
                    }
                    localStorage.setItem("user", JSON.stringify(localUser))
                    await checkToken(resultat.token)
                } else {
                    setUsernameError("Identifiant ou mot de passe incorrect")
                    setPasswordError("Identifiant ou mot de passe incorrect")
                }
            } else {
                setUsernameError("Identifiant ou mot de passe incorrect")
                setPasswordError("Identifiant ou mot de passe incorrect")
            }
        } catch(err) {
            console.log(err)
        } finally {
            setDataLoading(false)
        }
    }

    const checkToken = async(userToken: string) => {
        try {
            setDataLoading(true)
            const response = await api.post("/user/login").catch((error) => { return error.response })

            if(response.status === 200){
                let resultat = response.data
                if(resultat.username) {
                    resultat.token = userToken
                    setUsernameError("")
                    setPasswordError("")
                    localStorage.setItem("user", JSON.stringify(resultat))
                    dispatch(ACT_USER_CONNECT(resultat))
                } else {
                    setUsernameError("Identifiant ou mot de passe incorrect")
                    setPasswordError("Identifiant ou mot de passe incorrect")
                }
            } else {
                setUsernameError("Identifiant ou mot de passe incorrect")
                setPasswordError("Identifiant ou mot de passe incorrect")
            }
        } catch(err) {
            console.log(err)
        } finally {
            setDataLoading(false)
        }
    }

    return (
        <>
        { connectedUser ? (
            <Navigate to="/flyers" />
        ) : (
            <div id="loginContainer" style={{ backgroundColor: theme.palette.primary.dark }}>
                <AppTitle alignement="center" />
                <Paper id="loginBloc" elevation={24}>
                    <Typography variant="h3" component="h2">S'identifier</Typography>
                    <TextField label="Utilisateur" autoFocus required fullWidth error={ usernameError !== "" } helperText={usernameError} value={username} onChange={(e) => setUsername(e.target.value)} />
                    <TextField label="Mot de passe" type="password" required fullWidth error={ passwordError !== "" } helperText={passwordError} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
                    <Button variant="contained" size="large" fullWidth disabled={dataLoading} onClick={() => connectUser()}>S'identifier</Button>
                    <Divider id="loginDivider" />
                    <Typography variant="overline">© 2023-2024 Kévin Carnal &bull; Cinapps v2.1</Typography>
                </Paper>
            </div>
        ) }
        </>
    )
}

export default LoginPage