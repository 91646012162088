import './AppTitle.css'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import { Icon, Typography } from '@mui/material'

type Props = {
    alignement?: "left" | "center"
}

const AppTitle = ({ alignement }: Props) => {
    let marginProperty

    switch(alignement){
        case "left":
            marginProperty = { margin: "auto auto auto 0" }
            break 
        case "center":
            marginProperty = { margin: "0 auto 20px auto" }
            break
        default:
            marginProperty = { margin: "auto" }
    }

    return (
        <div id="title" style={marginProperty}>
            <Icon id="iconTitle">
                <GroupWorkIcon />
            </Icon>
            <Typography variant="h5" component="h1">
                CINAPPS {process.env.REACT_APP_ENV_NAME && (<small id="smallSubTitle">{process.env.REACT_APP_ENV_NAME}</small>)}
            </Typography>
        </div>
    )
}

export default AppTitle