import { Routes, Route } from 'react-router-dom'
import HtmlPage from '../pages/HtmlPage'
import UserPage from '../pages/UserPage'
import FlyersPage from '../pages/FlyersPage/FlyersPage'
import LoginPage from '../pages/LoginPage/LoginPage'
import AppLayout from '../components/core/AppLayout/AppLayout'
import UserAccessProvider from '../providers/UserAccessProvider'
import HomePage from '../pages/HomePage/HomePage'
import FlyersEditPage from '../pages/FlyersEditPage/FlyersEditPage'

const Routing = () => {
    return (
        <Routes>
            <Route element={<UserAccessProvider />}>
                <Route element={<AppLayout />}>
                    <Route path="/flyers" element={<FlyersPage />} />
                    <Route path="/flyers/edit/:movieId" element={<FlyersEditPage />} />
                    <Route path="/user/profile" element={<UserPage />} />
                    <Route path="/page/*" element={<HtmlPage />} />
                    <Route path="*" element={<HomePage />} />
                </Route>
            </Route>
            <Route path="/user/login" element={<LoginPage />} />
        </Routes>
    )
}

export default Routing