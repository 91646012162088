import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import store from "./store/store"
import "./index.css"
import Routing from "./services/Routing"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./styles/theme"
import SnackMessage from "./components/feedback/SnackMessage"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackMessage />
          <Routing />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)