import { Fab, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Snackbar, Alert } from "@mui/material"
import FeedbackIcon from '@mui/icons-material/Feedback'
import { useState } from "react"
import api from "../../services/api"
import { useDispatch } from "react-redux"
import { ACT_MESSAGE_ALERT } from "../../store/actions"
import MessageAlert from "../../models/MessageAlert"

function FeedbackButton() {
    const [ open, setOpen ] = useState(false)
    const [ feedbackMsg, setFeedbackMsg ] = useState("")
    const dispatch = useDispatch()

    const handleConfirm = () => {
        setOpen(false)
        fetchFeedback()
    }

    const fetchFeedback = async () => {
        const newMessage: MessageAlert = { open: true, message: "", severity: undefined }

        try {
            const response = await api.post("/feedback", {
                message: feedbackMsg
            })
            .catch((error) => {
                return error.response
            })

            if(response.status === 200){
                newMessage.message = "Votre message a été transmis !"
                newMessage.severity = "success"
                setFeedbackMsg("")
            } else {
                newMessage.message = response.statusText
                newMessage.severity = "error"
            }
        } catch(err) {
            newMessage.message = "Erreur serveur"
            newMessage.severity = "error"
        } finally {
            dispatch(ACT_MESSAGE_ALERT(newMessage as any))
        }
    }

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Remplissez le champ ci-dessous afin de me laisser un feedback :
                    </DialogContentText>
                    <TextField autoFocus
                        margin="dense"
                        id="feedbackMsg"
                        label="Message"
                        fullWidth
                        multiline={true} 
                        rows={4}
                        variant="standard"
                        placeholder="Entrez votre message ici..." 
                        onChange={(e) => setFeedbackMsg(e.target.value)} 
                        value={feedbackMsg} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirm()}>Envoyer</Button>
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                </DialogActions>
            </Dialog>
            <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => setOpen(true)}>
                <FeedbackIcon />
            </Fab>
        </>
    )
}

export default FeedbackButton