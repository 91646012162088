import { Box, AppBar, Toolbar, IconButton, Drawer, Button, Menu as MuiMenu, MenuItem, ListItemIcon } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import PersonIcon from "@mui/icons-material/Person"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import LogoutIcon from "@mui/icons-material/Logout"
import FeedbackButton from "../../buttons/FeedbackButton"
import AppTitle from "../../typographies/AppTitle/AppTitle"
import Menu from "../../menus/Menu/Menu"
import Footer from "../Footer/Footer"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { ACT_USER_CONNECT } from "../../../store/actions"
import { selectConnectedUser } from "../../../store/selectors"
import "./AppLayout.css"
import { grey } from "@mui/material/colors"

const AppLayout = () => {
    const dispatch = useDispatch()
    const [anchorUserMenu, setAnchorUserMenu] = useState<HTMLElement | null>(null)
    const [mobileOpen, setMobileOpen] = useState(false)
    const userProfile = useSelector(selectConnectedUser())
    const navigate = useNavigate()
    const userMenuOpen = Boolean(anchorUserMenu);

    const handleMenuIconClick = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleMenuItemClick = (newLink: string) => {
        navigate(newLink)
        setAnchorUserMenu(null)
    }

    const handleUserItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorUserMenu(event.currentTarget);
    }

    const handleUserMenuClose = () => {
        setAnchorUserMenu(null);
    }

    const handleUserLogout = () => {
        dispatch(ACT_USER_CONNECT())
        localStorage.removeItem("user")
        setAnchorUserMenu(null)
    }

    return (
        <>
            <FeedbackButton />
            <Box id="boxContainer">
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton id="iconMenuButton" size="large" edge="start" aria-label="menu" sx={{ display: { xs: 'block', sm: 'none' } }} onClick={handleMenuIconClick}>
                            <MenuIcon />
                        </IconButton>
                        <AppTitle alignement="left" />
                        <Box>
                            <Button id="btnUser" 
                                onClick={handleUserItemClick} 
                                startIcon={<AccountCircleIcon />} 
                                endIcon={<ArrowDropDownIcon />}
                                aria-controls={userMenuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={userMenuOpen ? 'true' : undefined}>
                                {userProfile.firstName}
                            </Button>
                            <MuiMenu id="basic-menu"
                                anchorEl={anchorUserMenu}
                                open={userMenuOpen}
                                onClose={handleUserMenuClose}
                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                                <MenuItem onClick={() => handleMenuItemClick("/user/profile")}>
                                    <ListItemIcon>
                                        <PersonIcon fontSize="small" />
                                    </ListItemIcon>
                                    Profil
                                </MenuItem>
                                <MenuItem onClick={() => handleUserLogout()}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    Déconnexion
                                </MenuItem>
                            </MuiMenu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer id="drawerTemporary" variant="temporary" open={mobileOpen} onClose={handleMenuIconClick}>
                    <Toolbar />
                    <Menu setMobileOpen={setMobileOpen} />
                    <Footer />
                </Drawer>
                <Drawer id="drawerPermanent" variant="permanent" sx={{ display: { xs: 'none', sm:'flex' } }}>
                    <Toolbar />
                    <Menu />
                    <Footer />
                </Drawer>
                <Box id="boxMainContent" component="main">
                    <Toolbar sx={{ mb: 3 }} />
                    <Outlet />
                    <Box sx={{ height: "64px" }} />
                </Box>
            </Box>
        </>
    )
}

export default AppLayout