import { useSelector } from "react-redux"
import { selectConnectedUser } from "../store/selectors"
import { Navigate, Outlet } from "react-router-dom"

const UserAccessProvider = () => {
    const connectedUser = useSelector(selectConnectedUser())

    return (
        <>
            { !connectedUser ? (<Navigate to="/user/login" replace={true} />) : <Outlet /> }
        </>
    )
}

export default UserAccessProvider